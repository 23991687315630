import React, { useState } from "react";
import { useTable } from "react-table";

import "./Table.scss";

function Table({ data, headerBgColor, headerTxtColor }) {
  const [isLoggedIn, SetisLoggedIn] = useState(false);

  return (
    <table className="lotsTable">
      <thead className="tableHeader" style={{ backgroundColor: headerBgColor }}>
        <tr className="tableHeaderRow" style={{ color: headerTxtColor }}>
          <td className="tableHeaderBox headerNumberBox">Lot Number</td>
          <td className="tableHeaderBox">Status</td>
        </tr>
      </thead>
      <tbody className="tableBody">
        {data.map((data, key) => {
          return (
            <tr className="tableBodyRow" key={key}>
              <td className="tableBodyBox numberValueBox">
                <div className="tableBodyBoxText">{data.num}</div>
              </td>

              <td className="tableBodyBox statusValuesBox">
                {isLoggedIn ? (
                  <div className="tableBodyBoxText">
                    {data.isSold ? "Sold" : `$${data.price}`}
                  </div>
                ) : (
                  <div className="tableBodyBoxText">
                    {data.isSold ? "Sold" : "Available"}
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
