import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// import { Icon } from "@iconify/react";

import thespringimg2 from "./images/thespringsImage2plano.png";
import thespringimg3 from "./images/textName.png";

import "./TheSprings.scss";
import TSNavBar from "./components/Navbar/TSNavBar";

function TheSprings() {
  const pageColor = "white";
  document.body.style = `background: ${pageColor};`;

  return (
    <div className="theSpringcontent">
      <TSNavBar />
      <div className="imagenes">
        <div className="img-container">
          <img src={thespringimg2} className="imagen" />
        </div>
      </div>
      <div className="main">
        <div className="imagenes">
          <div className="img-container">
            <img src={thespringimg3} className="imagen" />
          </div>
        </div>
        <div className="contentLink">
          <button className="btn-link">
            <a href="https://www.thespringscampestreclub.com/" target="_blank">
              Conocenos aqui..
            </a>
          </button>
        </div>

        <div className="contentInfo">
          <div className="textInfo">
            Calificado como ideal por la opinión de grandes personajes del mundo
            de los negocios, The Springs Golf Club rompe con todas las barreras
            colocándose como una de las mejores opciones de inversión en todos
            los aspectos, tanto en terreno financiero con gran plusvalía dentro
            del estado, así como la garantía de una vida plena donde comenzaras
            a vivir tu sueño.
          </div>
        </div>
      </div>

      <div className="footer">
        <div> © Whistle Coding</div>
      </div>
    </div>
  );
}

export default TheSprings;
