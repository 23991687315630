import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//style
import "./TSNavBar.scss";

//image
import imagelogo from "../../images/thesprings.png";

//fontawesoem
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function TSNavBar() {
  const [t, i18n] = useTranslation(["welcome"]);

  return (
    <div className="TSNavBar">
      <div className="header">
        <Link to="/" className="backButton">
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="backText">{t("seeMore")}</span>
        </Link>

        <div className="contentlogoandtext">
          <div className="contentimg">
            <img className="img" src={imagelogo} />
          </div>
          <div className="titleTextContainer">
            <div className="titleFirstText"> THE SPRINGS</div>
            <div className="titleSecText">Golf Club</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TSNavBar;
