import React from "react";
import { Link } from "react-router-dom";

import "./LogoCompanyCard.scss";

function LogoCompanyCard({
  translateLebaronVecindad,
  pageUrl,
  name,
  companyLogoImg,
  noLogoTextColor,
  topSectionBgColor,
  isAvailable,
  isSeparatePage,
  invertLogoColors,
}) {
  return (
    <div className="companyCardContainer">
      {isSeparatePage ? (
        <a href={pageUrl} className="logoCompanyCard">
          <EntireCard
            translateLebaronVecindad={translateLebaronVecindad}
            name={name}
            companyLogoImg={companyLogoImg}
            noLogoTextColor={noLogoTextColor}
            topSectionBgColor={topSectionBgColor}
            isAvailable={isAvailable}
            invertLogoColors={invertLogoColors}
          />
        </a>
      ) : (
        <Link to={pageUrl} className="logoCompanyCard">
          <EntireCard
            translateLebaronVecindad={translateLebaronVecindad}
            name={name}
            companyLogoImg={companyLogoImg}
            noLogoTextColor={noLogoTextColor}
            topSectionBgColor={topSectionBgColor}
            isAvailable={isAvailable}
            invertLogoColors={invertLogoColors}
          />
        </Link>
      )}
    </div>
  );
}

function EntireCard({
  translateLebaronVecindad,
  pageUrl,
  name,
  companyLogoImg,
  noLogoTextColor,
  topSectionBgColor,
  isAvailable,
  isSeparatePage,
  invertLogoColors,
}) {
  const bgColor = topSectionBgColor;

  const styles = {
    topBgColor: { backgroundColor: topSectionBgColor },
    noLogoColor: { color: noLogoTextColor },
  };

  return (
    <>
      <div className="topSection" style={styles.topBgColor}>
        {companyLogoImg ? (
          <>
            {invertLogoColors ? (
              <img
                src={companyLogoImg}
                alt=""
                className="invertedCompanyLogoImg"
              />
            ) : (
              <img src={companyLogoImg} alt="" className="companyLogoImg" />
            )}
          </>
        ) : (
          <div className="noLogoNameOfCompanyText" style={styles.noLogoColor}>
            {name}
          </div>
        )}
      </div>
      <div className="bottomSection">
        <div className="companyName">{name}</div>
        {isAvailable ? (
          <div className="isAvailableText">
            {translateLebaronVecindad("companyCard-available")}
          </div>
        ) : (
          <div className="notAvailableText">
            {translateLebaronVecindad("companyCard-soldOut")}
          </div>
        )}
      </div>
    </>
  );
}

// function TypeOfLink({ isSeparatePage, pageUrl }) {
//   return (
//     <>
//       {isSeparatePage ? <a href="youtube.com"></a> : <Link to={pageUrl}></Link>}
//     </>
//   );
// }

export default LogoCompanyCard;
