import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";



import { AppLanguage } from "../../../../AppLanguage";

import "./Header.scss";

import ChangeLanguageBtn from "../ChangeLanguageBtn/ChangeLanguageBtn";
import { logDOM } from "@testing-library/react";

library.add(faLanguage);

function Header({
  translateLebaronVecindad,
  englishLanguage,
  changeToEnglish,
  changeToSpanish,
}) {
  const [open, setOpen] = useState(false)



  return (
    <>
      <div className="lebaronVecindadHeader">
        <div className="content-computer">
          <div className="titleText">
            {translateLebaronVecindad("headerTitle")}
          </div>

          <div className="changeLanguageContainer">
            <ChangeLanguageBtn
              englishLanguage={englishLanguage}
              changeToEnglish={changeToEnglish}
              changeToSpanish={changeToSpanish}
            />
          </div>
        </div>

        <div className="content-movile">

          <div className="button-burgerMenu">
            {open ? (<FontAwesomeIcon icon={faLanguage} onClick={() => (setOpen(!open))} />) : (<FontAwesomeIcon icon={faLanguage} onClick={() => (setOpen(!open))} />)}
          </div>

          {open ? (
            <div className="lebaronVecindadMenu">
              <div className="changeLanguageContainerMenu">
                <ChangeLanguageBtn
                  englishLanguage={englishLanguage}
                  changeToEnglish={changeToEnglish}
                  changeToSpanish={changeToSpanish}
                />
              </div>
            </div>
          ) : null}

        </div>
      </div>

    </>
  );
}

export default Header;
