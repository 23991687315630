import React from 'react'
import './spiner.scss'
const Spiner = () => {
  return (
    <div className='content-sppiner'>
      <div className='loading-spinner'></div>
    </div>
  )
}

export default Spiner