export const listOfLots = [
  {
    lotPrice: null,
    isSold: true,
  },
  {
    lotPrice: 15,
    isSold: false,
  },
  {
    lotPrice: 15,
    isSold: false,
  },
  {
    lotPrice: 15,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 15,
    isSold: false,
  },
  {
    lotPrice: 22,
    isSold: false,
  },
  {
    lotPrice: null,
    isSold: true,
  },
  {
    lotPrice: 14,
    isSold: false,
  },
  {
    lotPrice: null,
    isSold: true,
  },
  {
    lotPrice: 14,
    isSold: false,
  },
  {
    lotPrice: null,
    isSold: true,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: null,
    isSold: true,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
  {
    lotPrice: 27,
    isSold: false,
  },
];
