import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import ChangeLanguageBtn from "./ChangeLanguageBtn/ChangeLanguageBtn";

import "./PDSNavbar.scss";

function PDSNavbar(props) {
  const [t, i18n] = useTranslation(["welcome"]);

  return (
    <div className="PDSNavbar">
      <Link to="/" className="backButton">
        <FontAwesomeIcon icon={faChevronLeft} />
        <span className="backText">{t("seeMore")}</span>
      </Link>
      <div className="titleTextContainer">
        <div className="titleText">Puesta De Sol</div>
      </div>
      <ChangeLanguageBtn
        currentLanguage={props.currentLanguage}
        changeToEnglish={props.changeToEnglish}
        changeToSpanish={props.changeToSpanish}
      />
    </div>
  );
}

export default PDSNavbar;
