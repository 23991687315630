import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import { useTranslation } from "react-i18next";
import Spiner from "./globalComponents/spiner/spiner";
// Pages
import HomePage from "./pages/lebaronVecindad/pages/HomePage/HomePage";
import RayosDeSolNorteHomePage from "./pages/rayosDeSolNorte/pages/HomePage/RayosDeSolNorteHomePage";
import PDSHomePage from "./pages/PuestaDeSol/pages/HomePage/PDSHomePage";
import TFLHomePage from "./pages/terrenoFrancisLeany/TFL_HomePage";
import CA_AboutPage from "./pages/cactusAcres/pages/AboutPage/CA_AboutPage";
import CA_ContactPage from "./pages/cactusAcres/pages/ContactPage/CA_ContactPage";
import TheSprings from "./pages/theSprings/TheSprings";

//hace la recarga de la paguina con lazy loading
const CA_HomePage = React.lazy(() =>
  import("./pages/cactusAcres/pages/HomePage/CA_HomePage")
);

const RayosDeSolSurHomePage = React.lazy(() =>
  import("./pages/rayosDeSolSur/pages/Homepage/RayosDeSolSurHomePage")
);

function App() {
  const [englishLanguage, setEnglishLanguage] = useState(true);
  const [t, i18n] = useTranslation(["welcome"]);
  const [translateLebaronVecindad, i19n] = useTranslation(["LV-homePage"]);

  const changeToEnglish = () => {
    i18n.changeLanguage("en");
    setEnglishLanguage(!englishLanguage);
  };

  const changeToSpanish = () => {
    i18n.changeLanguage("es");
    setEnglishLanguage(!englishLanguage);
  };

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              translateLebaronVecindad={translateLebaronVecindad}
              englishLanguage={englishLanguage}
              changeToEnglish={changeToEnglish}
              changeToSpanish={changeToSpanish}
            />
          }
          exact
        />

        {/* __________ Cactus Acres __________ */}

        <Route
          path="/cactusacres"
          element={
            <React.Suspense fallback={<Spiner />}>
              <CA_HomePage testCode={"true"} />
            </React.Suspense>
          }
        />
        <Route path="/cactusacres/about" element={<CA_AboutPage />} />
        <Route path="/cactusacres/contact" element={<CA_ContactPage />} />

        {/* __________ Other __________ */}

        <Route
          path="/rayos-de-sol-norte"
          element={<RayosDeSolNorteHomePage />}
        />

        <Route
          path="/rayos-de-sol-sur"
          element={
            <React.Suspense fallback={<Spiner />}>
              <RayosDeSolSurHomePage />
            </React.Suspense>
          }
        />

        <Route path="/puesta-de-sol" element={<PDSHomePage />} />

        <Route path="/terreno-francis-leany" element={<TFLHomePage />} />
        <Route path="/the-springs" element={<TheSprings />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
