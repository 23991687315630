import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

// Components
import ChangeLanguageBtn from "../ChangeLanguageBtn/ChangeLanguageBtn";

// Styles
import "./Navbar.scss";

// Images
import CactusLogo from "../../images/catusrecortado.png";

function Navbar(props) {
  const [open, setOpen] = useState(false);

  const [language, setLanguage] = useState(false);

  const [t, i18n] = useTranslation(["welcome"]);

  const changeToEnglish = () => {
    i18n.changeLanguage("en");
    setLanguage(!language);
  };
  const changeToSpanish = () => {
    i18n.changeLanguage("es");
    setLanguage(!language);
  };

  const currentpage = window.location.href;

  const localhost = "http://localhost:3000/#";

  const networkserver = "http://192.168.2.161:3000/#";

  const liveUrl = "https://lebaronvecindad.com/#";

  return (
    <>
      <div className="cactusAcresNavbar">
        <Link to="/" className="backButton">
          <FontAwesomeIcon icon={faChevronLeft} className="backBtnFont" />
          <span className="backText">{t("seeMore")}</span>
        </Link>
        <div className="logoContainer">
          <Link to="/">
            <img src={CactusLogo} alt="" className="logoImg" />
          </Link>
        </div>
        <div className="textContainer">
          <div className="titleTextMobile">CACTUS ACRES</div>
          <Link to="/cactusacres" className="titleTextLargeScreen">
            <div className="titleTextPiece textPiece-cactus">CACTUS</div>
            <div className="titleTextPiece textPiece-acres">ACRES</div>
          </Link>
        </div>
        <div className="linksContainer">
          <div className="allLinks">
            <Link
              to="/cactusacres"
              className={
                currentpage === `${liveUrl}/cactusacres`
                  ? "activeLink"
                  : "headerLink"
              }
            >
              <div className="linkText">{t("home")}</div>
            </Link>
            <Link
              to="/cactusacres/about"
              className={
                currentpage === `${liveUrl}/cactusacres/about`
                  ? "activeLink"
                  : "headerLink"
              }
            >
              <div className="linkText">{t("aboutUs")}</div>
            </Link>
            <Link
              to="/cactusacres/contact"
              className={
                currentpage === `${liveUrl}/cactusacres/contact`
                  ? "activeLink"
                  : "headerLink"
              }
            >
              <div className="linkText">{t("contact")}</div>
            </Link>
          </div>
        </div>
        <div className="rightSection">
          <div className="changeLanguageBtnContainer">
            <ChangeLanguageBtn
              currentLanguage={props.currentLanguage}
              changeToEnglish={props.changeToEnglish}
              changeToSpanish={props.changeToSpanish}
            />
          </div>
        </div>
        <div className="hamburgerContainer">
          {open ? (
            <FontAwesomeIcon
              icon={faXmark}
              className="closeDropdownIcon"
              onClick={() => setOpen(!open)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faBars}
              className="hamburgerIcon"
              onClick={() => setOpen(!open)}
            />
          )}
        </div>
        {open ? (
          <div className="dropdownContainer">
            <div className="dropdown">
              <div className="menu">
                <Link
                  to="/cactusacres"
                  className={
                    currentpage === `${liveUrl}/cactusacres`
                      ? "activePageLink"
                      : currentpage === `${networkserver}/cactusacres`
                      ? "activePageLink"
                      : "dropdownPageLink"
                  }
                  onClick={() => setOpen(!open)}
                >
                  <span className="menuLink">{t("home")}</span>
                </Link>
                <Link
                  to="/cactusacres/about"
                  className={
                    currentpage === `${liveUrl}/cactusacres/about`
                      ? "activePageLink"
                      : currentpage === `${networkserver}/cactusacres/about`
                      ? "activePageLink"
                      : "dropdownPageLink"
                  }
                  onClick={() => setOpen(!open)}
                >
                  <span className="menuLink">{t("aboutUs")}</span>
                </Link>
                <Link
                  to="/cactusacres/contact"
                  className={
                    currentpage === `${liveUrl}/cactusacres/contact`
                      ? "activePageLink"
                      : currentpage === `${networkserver}/cactusacres/contact`
                      ? "activePageLink"
                      : "dropdownPageLink"
                  }
                  onClick={() => setOpen(!open)}
                >
                  <span className="menuLink">{t("contact")}</span>
                </Link>

                <div className="dropdownItemBtn">
                  <ChangeLanguageBtn
                    isPhone={true}
                    currentLanguage={props.currentLanguage}
                    changeToEnglish={props.changeToEnglish}
                    changeToSpanish={props.changeToSpanish}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="pushContentDown"></div>
    </>
  );
}

export default Navbar;
