import React, { useState } from "react";
import "./ChangeLanguageBtn.scss";

function ChangeLanguageBtn({
  englishLanguage,
  changeToEnglish,
  changeToSpanish,
}) {
  return (
    <>
      {englishLanguage ? (
        <button className="mainChangeLanguageBtn" onClick={changeToEnglish}>
          <div className="btnText">Change to English</div>
        </button>
      ) : (
        <button className="mainChangeLanguageBtn" onClick={changeToSpanish}>
          <div className="btnText">Cambiar a Español</div>
        </button>
      )}
    </>
  );
}

export default ChangeLanguageBtn;
