import React from "react";

// Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LogoCompanyCard from "../../components/LogoCompanyCard/LogoCompanyCard";

// Images
import CactusAcresImg1 from "../../../../images/catus.png";
import TheSpringsImg1 from "../../../../images/thesprings.png";

// Styles
import "./HomePage.scss";

function HomePage({
  translateLebaronVecindad,
  englishLanguage,
  changeToEnglish,
  changeToSpanish,
}) {
  const pageColor = "rgb(245, 245, 245)";
  document.body.style = `background: ${pageColor};`;

  return (
    <div className="homePage">
      <Header
        translateLebaronVecindad={translateLebaronVecindad}
        englishLanguage={englishLanguage}
        changeToEnglish={changeToEnglish}
        changeToSpanish={changeToSpanish}
      />

      <div className="companyCardsContainer">
        <div className="companyCardsWrapper">
          <LogoCompanyCard
            translateLebaronVecindad={translateLebaronVecindad}
            pageUrl={"/the-springs"}
            name={"THE SPRINGS"}
            companyLogoImg={TheSpringsImg1}
            topSectionBgColor={" #e5583f"}
            noLogoTextColor={"#fef7ff"}
            isAvailable={true}
          />
          <LogoCompanyCard
            translateLebaronVecindad={translateLebaronVecindad}
            pageUrl={"/cactusacres"}
            name={"Cactus Acres"}
            companyLogoImg={CactusAcresImg1}
            noLogoTextColor={"#ffffff"}
            topSectionBgColor={"#E2BB8C"}
            isAvailable={true}
          />

          <LogoCompanyCard
            translateLebaronVecindad={translateLebaronVecindad}
            pageUrl={"/puesta-de-sol"}
            name={"PUESTA DE SOL"}
            topSectionBgColor={"#ffae00"}
            noLogoTextColor={"#351b64"}
            isAvailable={true}
          />
          <LogoCompanyCard
            translateLebaronVecindad={translateLebaronVecindad}
            pageUrl={"/rayos-de-sol-sur"}
            name={"Rayos De Sol Sur"}
            topSectionBgColor={"#005248"}
            noLogoTextColor={"#ec990a"}
            isAvailable={false}
          />
          <LogoCompanyCard
            translateLebaronVecindad={translateLebaronVecindad}
            pageUrl={"/rayos-de-sol-norte"}
            name={"Rayos De Sol Norte"}
            topSectionBgColor={"#005248"}
            noLogoTextColor={"#ec990a"}
            isAvailable={true}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;

{
  /*
  The props that can be passed in are here
  Los props que pueden passar estan aqui

  <LogoCompanyCard
    pageUrl={"/cactus-acres"}
    name={"Cactus Acres"}
    companyLogoImg={CactusAcresImg}
    topSectionBgColor={"#1b1c1d"}
    noLogoTextColor={"#ffae00"}
    invertLogoColors={true}
    isAvailable={true}
    isSeparatePage={true}
  />
*/
}
