import React from "react";

import { listOfLots } from "../../data/lotsPrices";

// Components
import RDSNNavbar from "../../components/Navbar/RDSNNavbar";
import Table from "../../../../globalComponents/Table/Table";

// Images
import imgOfLocations from "../../images/RDSN.jpeg";

// Styles
import "./RayosDeSolNorteHomePage.scss";

function RayosDeSolNorteHomePage() {
  const pageColor = "white";
  document.body.style = `background: ${pageColor};`;

  return (
    <>
      <RDSNNavbar />
      <div className="rayosDeSolNorteHomePage">
        <div className="heroSection">
          <div className="imgContainer">
            <img src={imgOfLocations} alt="" className="imgOfLots" />
          </div>

          <div className="listOfLotsContainer">
            <Table
              data={listOfLots}
              headerBgColor={"#005248"}
              headerTxtColor={"#ec990a"}
            />
          </div>
        </div>
        <div className="footerSection">
          <div className="descriptionText">
            Rayos de sol Norte es un vecindad Familiar en el Col.LeBaron de 22
            lotes.
          </div>
          <div className="contactUsSection">
            <div className="extraText">
              Si te interesa comprar un espacio; llena y envía el formulario, o
              envíanos un mensaje por
            </div>
            <div className="contactMethodsContainer">
              <p className="contactMethod">Email</p>
              <p> or </p>
              <p className="contactMethod">WhatsApp</p>
            </div>
            Con gusto te atendemos. Listo con Agua Y Camino: Primavera 2021 (se
            van a ir rapido, los Primeros lotes son de descuento).
          </div>
        </div>
      </div>
    </>
  );
}

export default RayosDeSolNorteHomePage;
